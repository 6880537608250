import React, { useEffect, forwardRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress'
import Print from '@mui/icons-material/Print';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MessageIcon from '@mui/icons-material/Message';
import Settings from '@mui/icons-material/Settings';
import { Order } from '../model/model';
import OrderCard from './OrderCard';
import { useSelector, useDispatch } from 'react-redux'
import { setOrder, getOrder, selectOrder, selectOrderLoading } from '../store/modules/order'
import { AppDispatch } from '../store/store'
import { acceptOrder, prepareOrder, orderHandlingLoading } from '../store/modules/order';

export interface Props {
  refOrder: Order;
  forwardRef?: React.Ref<HTMLDivElement>;
}

const OrderDetail = ({ refOrder, forwardRef }: Props) => {

  const order: Order = useSelector(selectOrder);
  const loading: boolean = useSelector(selectOrderLoading);
  const orderLoading: boolean = useSelector(orderHandlingLoading);
  const [printerActive, setPrinterActive] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (refOrder.orderId != order.orderId) {
      dispatch(setOrder(refOrder));
      dispatch(getOrder(refOrder.uuid));
    }
  }, []);
  
  const isNew = () => {
    if (
      order.orderStatus === "SCHEDULE_ORDERED" 
      || order.orderStatus === "ORDERED"
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isPreparing = () => {
    if (
      order.orderStatus === "ACCEPTED"
    ) {
      return true;
    } else {
      return false;
    }
  }
  
  const optionPriceFormat = (price:number) => {
    if (price === 0) {
      return '';
    } else {
      return price.toLocaleString();
    }
  }

  const makeLineBreak = (address:string) => {
    const array = address.split("\n");
    const texts = array.map((item, index) => {
      return (
        <React.Fragment key={index}>{item}
          {index === array.length - 1 ? <></> : <br />} 
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  }

  return (
    <>
      <Paper className='FontM' sx={{
        display: 'flex',
        flexFlow: 'column',
        width: 'calc(100% - 140px)',
        maxWidth: '1000px',
        maxHeight: '80vh',
        background: 'white',
        overflow: 'hidden',
      }}>
        <OrderCard
        order={order}
        />
        <Divider />
        <Grid container sx={{
          p: '10px 20px',
          overflowY: 'auto',
        }}>
          {loading ? <CircularProgress sx={{m:'auto'}} /> :<>
            {order.orderType !== 'INHOUSE_DELIVERY' ? '' :
              <>
              <Grid container mb='10px'>
                <Grid item xs={1} sx={{display: 'inline-flex'}}><LocationOnIcon sx={{ margin: 'auto 5px auto 0'}}/></Grid>
                <Grid item xs={11} className='FontS'>{makeLineBreak(order.delivery.address)}</Grid>
                <Grid item xs={12} mt='5px'><Divider /></Grid>
              </Grid>
              </>
            }
            {order.customerNote === '' ? '' :
              <>
              <Grid container mb='10px'>
                <Grid item xs={1} sx={{display: 'inline-flex'}}><MessageIcon sx={{ margin: 'auto 5px auto 0'}}/></Grid>
                <Grid item xs={11} className='FontS' color='red'>{makeLineBreak(order.customerNote)}</Grid>
                <Grid item xs={12} mt='5px'><Divider /></Grid>
              </Grid>
              </>
            }
            {order.items === undefined ? '' :
            <> {order.items.map((item, index) => (
              <Grid container mb='10px' key={index}>
                <Grid item xs={1} fontWeight='bold' textAlign='right' pr='20px' color={item.quantity > 1 ? 'red' : 'black' }>{item.quantity} x</Grid>
                <Grid item xs={9} fontWeight='bold'>{item.name}</Grid>
                <Grid item xs={2} textAlign='right' pr='20px'>{item.price.toLocaleString()}</Grid>
                {item.options.map((option, index) => (
                  <Grid container key={index} mt='5px'>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} className='FontS' sx={{color: 'gray'}}>{option.categoryName}</Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1} textAlign='right' pr='20px' color={option.quantity > 1 ? 'red' : 'black' }>{option.quantity} x</Grid>
                    <Grid item xs={8}>{option.name}</Grid>
                    <Grid item xs={2} textAlign='right' pr='20px'>{optionPriceFormat(option.price)}</Grid>
                  </Grid>
                ))}
              </Grid>
              ))}
              <Grid item xs={9}></Grid>
              <Grid item xs={3} borderTop='1px solid rgba(0,0,0,0.12)' textAlign='right'  pt='10px' pb='10px' pr='20px'>
                <Grid item xs={12}>小計 {order.subtotal.toLocaleString()}</Grid>
                <Grid item xs={12}>支払 {order.paymentTotal.toLocaleString()}</Grid>
              </Grid>
            </>
            }
            </>
          }
        </Grid>
        <Divider />
        <Grid container sx={{ p:'10px 20px' }}>
          <Grid item xs={2} sx={{ p:'0 20px' }}>
            <Button disabled={!printerActive} variant="contained" sx={{
              width:'100%',
              height:'40px',
              backgroundColor: 'black',
              '&:hover': {
                backgroundColor: '#282c34',
              },
            }}>
              <Print style={{ position:'absolute', width:'100%', height:'70%', top:'15%', color: 'white', zIndex:'0', opacity:'1' }} />
            </Button>
          </Grid>
          { (isNew() || isPreparing()) ?
          <>
            <Grid item xs={8} sx={{ p: '0 20px' }}>
              <Button variant="contained" sx={{
                  width:'100%',
                  height:'40px',
                  backgroundColor: 'black',
                  '&:hover': {
                    backgroundColor: '#282c34',
                  },
                }}
                onClick={() => {
                  if (isNew()) {
                    dispatch(acceptOrder({uuid: order.uuid, time: 0}));
                  } else {
                    dispatch(prepareOrder(order.uuid));
                  }
                }}
                disabled={orderLoading}
              >
                {orderLoading ? 
                  <CircularProgress sx={{ color:'black', height:'30px', width:'30px' }} />
                  : <>{isNew() ? '受注' : '調理完了'}</>
                }
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ p: '0 20px' }}>
              <Button variant="contained" sx={{
                width:'100%',
                height:'40px',
                backgroundColor: 'black',
                '&:hover': {
                  backgroundColor: '#282c34',
                },
              }}
              >
                <Settings style={{ position:'absolute', width:'100%', height:'70%', top:'15%', color: 'white', zIndex:'0', opacity:'1' }} />
              </Button>
            </Grid>
          </> : <></>
          }
        </Grid>
      </Paper>
    </>
  );
};

export default OrderDetail;