import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import Orders from './Orders';
import { useDispatch, useSelector } from 'react-redux';
import { getLocation, selectLocation } from '../store/modules/user';
import { AppDispatch } from '../store/store';

const Home = () => {
  const location = useSelector(selectLocation);
  
  const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      } else {
        dispatch(getLocation());
      }
    });
  }, []);

  return (
    <div className='App'>
      {location.uuid === '' ? <></> : <Orders locationUuid={location.uuid} />}
    </div>
  );
};

export default Home;