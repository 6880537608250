import { useState } from 'react';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';

import Divider from '@mui/material/Divider';
import { Switch } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";
import { resetUser, selectLocation } from '../store/modules/user';
import { useDispatch, useSelector } from 'react-redux';

import ubereats from '../assets/ubereats.png'
import demaecan from '../assets/demaecan.png'
import menu from '../assets/menu.png'
import wolt from '../assets/wolt.png'
import square from '../assets/square.png'
import takeme from '../assets/takeme.png'

import { selectShowOfo, setShowUber, setShowDemaecan, setShowMenu, setShowWolt, setShowSquare, setShowTakeme } from '../store/modules/order';
import { AppDispatch } from '../store/store';

const Sidenav = () => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch<AppDispatch>();
  const handleChange = (e:any) => {
    switch (e.target.ariaLabel) {
      case 'ubereats':
        dispatch(setShowUber(e.target.checked));
        break;
      case 'demaecan':
        dispatch(setShowDemaecan(e.target.checked));
        break;
      case 'menu':
        dispatch(setShowMenu(e.target.checked));
        break;
      case 'wolt':
        dispatch(setShowWolt(e.target.checked));
        break;
      case 'square':
        dispatch(setShowSquare(e.target.checked));
        break;
      case 'takeme':
        dispatch(setShowTakeme(e.target.checked));
        break;
      default:
        break;
    }
  };

  const signout = async () => {
    await signOut(auth);
    dispatch(resetUser());
    localStorage.removeItem('token');
  }

  const location = useSelector(selectLocation);
  const showOfo = useSelector(selectShowOfo);

  return (
    <>
      <List sx={{p: '0 20px'}}>
        <h2 style={{ 'textAlign': 'center' }}>Unifire</h2>
        <p>{location.organizationName}</p>
        <p>{location.name}</p>
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader>
            Setting
          </ListSubheader>
        }
      >
        <ListItemButton
          onClick={handleClick}
          key='platform'
        >
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText primary="Platform" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{p: '10px 16px', height: '30px'}}>
            <img src={ubereats} alt="Uber Eats" className='Ofo-logo' style={{width:'30px', height: '30px', marginRight: '26px'}} />
            <Switch
              checked={showOfo.showUber}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'ubereats' }}
            />
          </List>
          <List sx={{p: '10px 16px', height: '30px'}}>
            <img src={demaecan} alt="Demaecan" className='Ofo-logo' style={{width:'30px', height: '30px', marginRight: '26px'}} />
            <Switch
              checked={showOfo.showDemaecan}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'demaecan' }}
            />
          </List>
          <List sx={{p: '10px 16px', height: '30px'}}>
            <img src={menu} alt="menu" className='Ofo-logo' style={{width:'30px', height: '30px', marginRight: '26px'}} />
            <Switch
              checked={showOfo.showMenu}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'menu' }}
            />
          </List>
          <List sx={{p: '10px 16px', height: '30px'}}>
            <img src={wolt} alt="Wolt" className='Ofo-logo' style={{width:'30px', height: '30px', marginRight: '26px'}} />
            <Switch
              checked={showOfo.showWolt}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'wolt' }}
            />
          </List>
          <List sx={{p: '10px 16px', height: '30px'}}>
            <img src={square} alt="Square" className='Ofo-logo' style={{width:'30px', height: '30px', marginRight: '26px'}} />
            <Switch
              checked={showOfo.showSquare}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'square' }}
            />
          </List>
          <List sx={{p: '10px 16px', height: '30px'}}>
            <img src={takeme} alt="TakeMe" className='Ofo-logo' style={{width:'30px', height: '30px', marginRight: '26px'}} />
            <Switch
              checked={showOfo.showTakeme}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'takeme' }}
            />
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            signout();
          }}
          key='signout'
        >
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Signout" />
        </ListItemButton>
      </List>
    </>
  )
}

export default Sidenav;