import Sidenav from '../components/Sidenav';
import OrderCard from '../components/OrderCard';
import OrderDetail, { Props } from '../components/OrderDetail'
import { AppDispatch } from '../store/store';
import { Order } from '../model/model';
import { getCompletedOrders, selectCompletedOrders, selectTotalOrder, selectTotalSales, selectCompletedOrderListLoading, resetOrderList, selectIsNew, selectOfoParam } from '../store/modules/order';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress'
import MenuIcon from '@mui/icons-material/Menu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import dayjs from 'dayjs';
import useSound from 'use-sound';
import Sound from '../assets/alert.mp3';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell } from '@mui/material';
import useFirebase from '../hooks/useFirebase';


const Orders = (props:{locationUuid: string}) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeLoading, setActiveLoading] = useState(false);
  const [modalSelect, setModalSelect] = useState(new Order());
  const [dateSelect, setDateSelect] = useState(new Date());
  const [showComplete, setShowComplete] = useState(false);
  const completedOrders: Order[] = useSelector(selectCompletedOrders);
  const totalOrder: number = useSelector(selectTotalOrder);
  const totalSales: number = useSelector(selectTotalSales);
  const completedLoading: boolean = useSelector(selectCompletedOrderListLoading);
  const isNew: boolean = useSelector(selectIsNew);
  const ofoParam: string = useSelector(selectOfoParam);

  const { orders: activeOrders } = useFirebase(props.locationUuid);

  // order notification
  const [isPlaying, setPlaying] = useState(false);
  const [play, { stop }] = useSound(Sound, {
    onend: () => {
      setPlaying(false);
    },
    interrupt: false,
  });
  const checkNew = () => {
    if (activeOrders.filter(order => order.orderStatus == "ORDERED").length > 0) {
      if (!isPlaying) {
        setPlaying(true);
        play();
      }
    } else {
      setPlaying(false);
      stop();
    }
  }
  const useSoundInterval = (callback: () => void) => {
    const callbackRef = useRef<() => void>(callback);
    useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
    
    useEffect(() => {
      const tick = () => { callbackRef.current() } 
      const id = setInterval(tick, 2000);
      return () => {
        checkNew();
        clearInterval(id);
      };
    }, []);
  };
  useSoundInterval(() => {
    checkNew();
  })

  const modalOpen = (order:Order) => {
    setShowModal(true);
    setModalSelect(order);
  };

  const modalClose = () => {
    setShowModal(false);
    setModalSelect(new Order());
  }

  const dispatch = useDispatch<AppDispatch>();

  // const useInterval = (callback: () => void) => {
  //   const callbackRef = useRef<() => void>(callback);
  //   useEffect(() => {
  //     callbackRef.current = callback;
  //   }, [callback]);
    
  //   useEffect(() => {
  //     dispatch(getActiveOrders('?'+ofoParam));
  //     const tick = () => { callbackRef.current() } 
  //     const id = setInterval(tick, 20000);
  //     return () => {
  //       dispatch(getActiveOrders('?'+ofoParam));
  //       clearInterval(id);
  //     };
  //   }, []);
  // };
  // useInterval(() => {
  //   dispatch(getActiveOrders('?'+ofoParam));
  // });
  const selectDate = (newDate: Date | null) => {
    var date = new Date();
    if(newDate !== null) {
      date = newDate;
    }
    dispatch(resetOrderList());
    const dateStr = dayjs(date).format('YYYY-MM-DD');
    setDateSelect(date);
    dispatch(getCompletedOrders('?date='+dateStr+'&'+ofoParam));
  }
  const selectCompleted = () => {
    setShowComplete(true);
    selectDate(null);
  }

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({refOrder}, ref) => {
      return <OrderDetail refOrder={refOrder} forwardRef={ref} />;
    }
  );

  return (
    <>
      <Drawer
        anchor={'left'}
        open={drawerOpened}
        onClose={() => setDrawerOpened(false)}>
        <Sidenav />
      </Drawer>
      
      <div className='Side-menu'>
        <Box sx={{
            height: '80px',
            marginBottom: '20px',
            backgroundColor: '#282c34',
          }}
          onClick={() => setDrawerOpened(true)}>
          <MenuIcon style={{ width:'40px', height: '40px' }} />
        </Box>
        <Box sx={showComplete ? {
            height: 'calc(50vh - 70px)',
            backgroundColor: '#282c34',
          } : {
            height: 'calc(50vh - 70px)',
            backgroundColor: '#7f878f',
          }}
          onClick={() => setShowComplete(false)}
        >
          <FiberNewIcon style={{ width:'40px', height: '40px' }} />
        </Box>
        <Box sx={showComplete ? {
            height: 'calc(50vh - 70px)',
            backgroundColor: '#7f878f',
          } : {
            height: 'calc(50vh - 70px)',
            backgroundColor: '#282c34',
          }}
          onClick={() => selectCompleted()}>
          <AssignmentTurnedInIcon style={{ width:'40px', height: '40px' }} />
        </Box>
      </div>

      <Grid container sx={{ m:'0 auto'}}>
        <Grid item xs={6} sx={{ height:'40px', lineHeight: '40px' }}>
          {showComplete ? '¥ ' + totalSales.toLocaleString() + ' / ' + totalOrder + ' orders' : ''}
        </Grid>
        <Grid item xs={6} sx={{ height:'40px', lineHeight: '40px', textAlign: 'right' }}>
          {showComplete ? 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={dateSelect}
              onChange={selectDate}
              inputFormat='YYYY/MM/DD'
              mask='____/__/__'
              renderInput={({inputRef, inputProps, InputProps}) => (
                <div className='Date-input'>
                  <input ref={inputRef} {...inputProps} />
                  {InputProps?.endAdornment}
                </div>
              )}
            />
          </LocalizationProvider>
          : <>
            {dayjs().format('M/DD HH:mm')}
          </>
          }
        </Grid>
          <Grid item xs={12} sx={{
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(100vh - 100px)',
            marginTop: '20px',
            overflowY: 'auto',
          }}>
            {((showComplete && completedLoading) || (!showComplete && activeLoading)) ?
              <div style={{textAlign:'center'}}><CircularProgress /></div> :
              <TableContainer component={Paper} sx={{
                bgcolor: 'transparent',
                width: '100%',
                maxWidth: '1000px',
              }}>
              <Table aria-label='simple table'>
                  <TableBody>
                    {(showComplete ? completedOrders : activeOrders)
                      .map((row:Order) => (
                        <TableRow
                          sx={{m: '10px auto', display: 'flex'}}
                          key={row.uuid}
                          onClick={() => modalOpen(row)}
                        >
                          <TableCell sx={{
                            p:'0',
                            width: '100%',
                            background: 'white',
                            border:'none',
                            borderRadius: '4px',
                            overflow: 'hidden'
                          }}>
                            <OrderCard 
                              order={row}
                            />
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
              </Table>
              </TableContainer>
            }
          </Grid>
        </Grid>
      <Modal
        open={showModal}
        onClose={() => modalClose()}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Ref refOrder={modalSelect} />
      </Modal>
    </>
  );
};

export default Orders;