import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector, useDispatch } from 'react-redux'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Hail from '@mui/icons-material/Hail';
import DeliveryDining from '@mui/icons-material/DeliveryDining';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import FiberNew from '@mui/icons-material/FiberNew';

import ubereats from '../assets/ubereats.png'
import demaecan from '../assets/demaecan.png'
import menu from '../assets/menu.png'
import wolt from '../assets/wolt.png'
import square from '../assets/square.png'
import takeme from '../assets/takeme.png'

import dayjs from 'dayjs';

import { Order } from '../model/model';
import { AppDispatch } from '../store/store'
import { prepareOrder, orderHandlingLoading } from '../store/modules/order';

const img = (slug:string) => {
  switch(slug) {
    case 'ubereats': return ubereats;
    case 'demaecan': return demaecan;
    case 'menu': return menu;
    case 'wolt': return wolt;
    case 'square': return square;
    case 'takeme': return takeme;
    default: return '';
  }
}

const OrderCard = (props:{order: Order}) => {
  const dispatch = useDispatch<AppDispatch>();
  const loading: boolean = useSelector(orderHandlingLoading);

  const isNew = () => {
    if (
      props.order.orderStatus === 'SCHEDULE_ORDERED' 
      || props.order.orderStatus === 'ORDERED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isPreparing = () => {
    if (
      props.order.orderStatus === 'ACCEPTED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isActive = () => {
    if (
      props.order.orderStatus === 'SCHEDULE_ORDERED' 
      || props.order.orderStatus === 'SCHEDULE_ACCEPTED'
      || props.order.orderStatus === 'ORDERED'
      || props.order.orderStatus === 'ACCEPTED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isScheduled = () => {
    if (
      props.order.orderStatus === 'SCHEDULE_ORDERED' 
      || props.order.orderStatus === 'SCHEDULE_ACCEPTED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isComplete = () => {
    if (
      props.order.orderStatus === 'COMPLETED' 
      || props.order.orderStatus === 'CANCELED'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const timeLabel = () => {
    if (isActive()) {
      if (isScheduled()) {
        // pickupのほうがよい？？
        const time = dayjs(props.order.estimatedCompleteAt);
        if(time.format('YYYY-MM-DD') === dayjs().format("YYYY-MM-DD")) {
          return dayjs(props.order.estimatedCompleteAt).format('H:mm');
        } else {
          return dayjs(props.order.estimatedCompleteAt).format('M/D\nH:mm');
        }
      } else {
        const time = dayjs(props.order.estimatedPickupAt);
        return time.diff(dayjs(), 'minutes') + 1 + ' min';
      }
    } else {
			return dayjs(props.order.orderedAt).format('HH:mm');
		}
  }

  const statusLabel = () => {
    // complete status
    if (props.order.orderStatus === 'COMPLETED') {
      if (props.order.orderType === 'PICKUP') {
        if (props.order.isScheduled) {
          return dayjs(props.order.completedAt).format('HH:mm') + ' 受取';
        }
      }
      return '完了';
    }
    if (props.order.orderStatus === 'CANCELED') {
      return 'キャンセル';
    }

    // delivery status
    if (props.order.orderType !== 'OFO_DELIVERY') {
      return null;
    }
    if (props.order.orderStatus === 'PICKED') {
      return '配達中';
    }
    if (props.order.deliveryStatus == 'ARRIVED') {
      return 'まもなく到着';
    }
    if (props.order.deliveryStatus == 'MATCHED') {
      const mins = dayjs(props.order.estimatedPickupAt).diff(dayjs(), 'minutes');
      if (mins > 0) {
        return mins + ' min';
      } else {
        return 'まもなく到着';
      }
    }
    if (props.order.deliveryStatus == 'REQUESTING') {
      return '検索中';
    }
    return null;
  }
  
  const statusColor = () => {
    if (isActive()) {
      if (props.order.orderStatus === 'ORDERED' || props.order.orderStatus === 'SCHEDULE_ORDERED' ) {
				return '#cbf266';
			} else if (props.order.orderStatus === 'SCHEDULE_ACCEPTED') {
				return '#b4ebfa';
			} else if (props.order.orderStatus === 'PAUSED') {
				return 'red';
			}
      const time = dayjs(props.order.estimatedPickupAt);
      const timeDiff = time.diff(dayjs(), 'minutes') + 1;
      if (timeDiff <= 5) {
        return '#ff9900';
      } else {
        return '#fff500';
      } 
    } else if (isComplete()) {
      if (props.order.isCanceled) {
        // app修正したら削除
        if (props.order.storeSales == 0) {
          return 'red';
        }
      }
      return 'darkgray';
    } else {
      return 'lightgray';
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={9} sm={10}>
          <Grid container sx={{p: {xs: '5px 10px', sm: '10px 20px'}}}>
            <Grid item xs={12}>
              <Grid container sx={{
                width: '100%',
                justifyContent: 'center',
              }}>
                <Grid item xs={2} sm={1.5} sx={{m: 'auto', pr: {xs: '5px', sm: '20px'}}}>
                  <img src={img(props.order.ofoSlug)} alt={props.order.ofoName} className='Ofo-logo' />
                </Grid>
                <Grid item xs={7} sm={3} className='FontL' sx={{m: 'auto', fontWeight: 'bold'}}># {props.order.displayId}</Grid>
                <Grid item xs={0} sm={1} className='FontM' sx={{m: 'auto', textAlign: 'center', pr: '10px', display: {xs: 'none', sm: 'block'}}}>
                  {props.order.customerCount === 0 ? '' :
                    <span
                      className='Circle'
                      style={{'background':props.order.customerCount >= 5 ? 'red' : props.order.customerCount >= 2 ? 'blue' : 'gray'}}
                    >{props.order.customerCount}</span>
                  }
                </Grid>
                <Grid item xs={0} sm={5.5} className='FontL' sx={{m: 'auto', display: {xs: 'none', sm: 'block'}}}>
                  {props.order.customerName}
                </Grid>
                <Grid item xs={2} sm={1}  className='fontS' sx={{m: 'auto', display: 'inline-flex', alignItems: 'center' }}>
                  {props.order.orderType === 'INHOUSE_DELIVERY' ?
                    <Hail style={{ color: 'red', width: 'calc(20px + 1vw)', height: 'calc(20px + 1vw)', margin: 'auto 5px auto 0' }} /> :
                  props.order.orderType === 'PICKUP' ?
                    <DirectionsWalk style={{ width: 'calc(20px + 1vw)', height: 'calc(20px + 1vw)', margin: 'auto 5px auto 0' }} /> :
                    <DeliveryDining style={{ width: 'calc(20px + 1vw)', height: 'calc(20px + 1vw)', margin: 'auto 5px auto 0' }} />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className='FontS' mt='5px'>
              {props.order.brandName}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} sm={2}  className='FontL' sx={{
          borderRadius: '0',
          color: 'black',
          position: 'relative',
          display: 'grid',
          background: statusColor(),
        }}>
          {isPreparing() ? 
            <Button sx={{
                width: '100%',
                height: '100%',
                borderRadius: '0',
                color: 'black',
                position: 'relative',
                display: 'flex',
                background: `linear-gradient(to bottom, white 5%, ${statusColor()} 100%)`,
                cursor: 'pointer',
                '&:hover': {
                  background: `linear-gradient(to bottom, ${statusColor()} 5%, white 100%)`
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                dispatch(prepareOrder(props.order.uuid));
              }}
              disabled={loading}
            >
              {loading ? 
                <CircularProgress /> :
                <>
                  <CheckCircleOutlineIcon style={{ position:'absolute', width:'100%', height:'60%', top:'20%', color: 'white', zIndex:'0', opacity:'0.7' }} />
                  <span className='FontL' style={{ margin:'auto', fontWeight: 'bold', zIndex:'999' }}>{timeLabel()}</span>
                </>
              }
            </Button> :
            <>
              {isNew() ? 
                <FiberNew style={{ position:'absolute', width:'100%', height:'70%', top:'15%', color: 'white', zIndex:'0', opacity:'1' }} /> : <></>  
              }
              <span className='FontL' style={{ margin:'auto', fontWeight: 'bold', zIndex:'999' }}>{timeLabel()}</span>
              {statusLabel() !== null ?
                <span className='FontS' style={{ margin:'auto', zIndex:'999' }}>{statusLabel()}</span> : <></>
              }
            </>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default OrderCard;