import { configureStore } from '@reduxjs/toolkit';
import order from './modules/order';
import user from './modules/user';

const store = configureStore({
  reducer: {
    user: user,
    order: order,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch;
export default store;