import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { Order } from "../model/model";
import dayjs from "dayjs";

const useFirebase = (locationUuid: string) => {
	const [orders, setOrders] = useState<Order[]>([]);
	
	useEffect(() => {
		const ordersCollectionRef = collection(db, 'orders');
		const queryOption = [
			where("location_uuid", "==", locationUuid),
			where("order_status", "in", ["SCHEDULE_ORDERED", "SCHEDULE_ACCEPTED", "ORDERED", "ACCEPTED"]),
			orderBy('ordered_at', 'desc'),
		];
		const q = query(ordersCollectionRef, ...queryOption);

		const unsub = onSnapshot(q, (querySnapshot) => {
			let results:Order[] = [];
			querySnapshot.docs.forEach((doc) => {
				const data = doc.data();
				let newOrder = new Order({
					uuid: data.uuid,
					storeUuid: data.store_uuid,
					ofoSlug: data.ofo_slug,
					ofoName: data.ofo_name,
					brandUuid: data.brand_uuid,
					brandName: data.brand_name,
					locationUuid: data.location_uuid,
					locationName: data.location_name,
					orderId: data.order_id,
					displayId: data.display_id,
					customerName: data.customer_name,
					customerCount: data.customer_count,
					customerNote: data.customer_note,
					date: data.date,
					orderedAt: dayjs(data.ordered_at.seconds*1000).format('YYYY-MM-DDTHH:mm:ssZ'),
					estimatedPickupAt: dayjs(data.estimated_pickup_at.seconds*1000).format('YYYY-MM-DDTHH:mm:ssZ'),
					estimatedCompleteAt: dayjs(data.estimated_complete_at.seconds*1000).format('YYYY-MM-DDTHH:mm:ssZ'),
					orderType: data.order_type,
					orderStatus: data.order_status,
					isScheduled: data.is_scheduled,
					isCanceled: data.is_canceled,
				});
				results.push(newOrder);
			});
			setOrders(results);
				// querySnapshot.docChanges().forEach(change => {
				//   if (change.type === 'added') {
				//     console.log('New order: ', change.doc.data());
				//   }
				//   if (change.type === 'modified') {
				//     console.log('Modified order: ', change.doc.data());
				//   }
				//   if (change.type === 'removed') {
				//     console.log('Removed order: ', change.doc.data());
				//   }
				// });
		});
		return () => unsub();
	}, [locationUuid]);

	return { orders };
};



export default useFirebase;

