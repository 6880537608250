import { Record } from 'immutable';

export class ItemChildOption extends Record({
  name: '',
  categoryName: '',
  quantity: 0,
  price: 0.0,
  note: '',
}) {
}

export class ItemOption extends Record({
  name: '',
  categoryName: '',
  quantity: 0,
  price: 0.0,
  note: '',
  options: [new ItemChildOption()],
}) {
}

export class OrderItem extends Record({
  name: '',
  categoryName: '',
  quantity: 0,
  price: 0.0,
  note: '',
  options: [new ItemOption()],
}) {
}

export class OrderDelivery extends Record({
  address: '',
  phoneNumber: '',
  note: '',
}) {
}

export class Order extends Record({
  uuid: '',
  storeUuid: '',
  ofoSlug: '',
  ofoName: '',
  brandUuid: '',
  brandName: '',
  locationUuid: '',
  locationName: '',
  orderId: '',
  displayId: '',
  customerName: '',
  customerCount: 0,
  customerNote: '',
  date: '',
  orderedAt: '',
  estimatedPickupAt: '',
  estimatedCompleteAt: '',
  canUpdateTime: false,
  driverName: '',
  deliveryStatus: '',
  completedAt: '',
  subtotal: 0,
  storeSales: 0,
  paymentTotal: 0,
  paymentType: '',
  isPaid: true,
  orderType: '',
  orderStatus: '',
  isScheduled: false,
  isCanceled: false,
  items: [new OrderItem()],
  itemsV2: [new OrderItem()],
  delivery: new OrderDelivery(),
}) {
}