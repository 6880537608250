import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
  location: {
    uuid: '',
    name: '',
    address: '',
    organizationName: '',
  },
  loading: true,
  error: false,
}

export const getLocation = createAsyncThunk('user/getLocation', async () => {
  const res = await axios.get('/locations');
  return res.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      resetUser: (state) => {
        state.location = initialState.location;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getLocation.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.location = action.payload.data;
      });
      builder.addCase(getLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    },
})

export const { resetUser } = userSlice.actions;

export const selectLocation = (state: any) => {return state.user.location};

export default userSlice.reducer;