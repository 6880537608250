import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";

import '../App.css';

const fieldStyle = {
  '& .MuiInputBase-input': {
    color: 'white',    // 入力文字の色
    p: '10px 20px',
  },
  '& label': {
    color: 'rgb(255,255,255,0.3)', // 通常時のラベル色 
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white', // 通常時のボーダー色
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',  // ホバー時のボーダー色
  },
};

const Signin = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e:any) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (auth.currentUser !== null) {
        const token = await auth.currentUser.getIdToken(true)
        localStorage.setItem('token', token);
        navigate('/order');
      }
    } catch(error) {
      alert('FAILED LOGIN');
    }
  };

  return (
    <div className="App">
      <header style={{
          margin: 'auto',
          padding: '20px',
          backgroundColor: '#282c34',
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center'
      }}>
        <h1>Unifire</h1>
        <form onSubmit={onSubmit}>
          <TextField
            type='email'
            label='Email'
            variant='standard'
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={fieldStyle}
          />
          <TextField
            type='password'
            label='Password'
            variant='standard'
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={fieldStyle}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt:'20px', p:'5px 40px' }}
          >
            Signin
          </Button>
        </form>
      </header>
    </div>
  );
};

export default Signin;