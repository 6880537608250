import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import {Order} from '../../model/model';

const initialState:{
  order: Order,
  printingOrder: Order | null,
  orderLoading: boolean,
  active: Order[],
  completed: Order[],
  totalOrder: number,
  totalSales: number,
  activeLoading: boolean,
  completedLoading: boolean,
  handleLoadings: {[key: string]: boolean},
  isNew: boolean,
  showOfo: {
    showUber: boolean,
    showDemaecan: boolean,
    showMenu: boolean,
    showWolt: boolean,
    showSquare: boolean,
    showTakeme: boolean,
  }
} = {
  order: new Order(),
  printingOrder: null,
  orderLoading: true,
  active: [],
  completed: [],
  totalOrder: 0,
  totalSales: 0,
  activeLoading: true,
  completedLoading: false,
  handleLoadings: {},
  isNew: false,
  showOfo: {
    showUber: true,
    showDemaecan: true,
    showMenu: true,
    showWolt: true,
    showSquare: true,
    showTakeme: true,
  }
}

export const getOrder = createAsyncThunk('orders/getOrder', async (uuid:string) => {
  const res = await axios.get('/orders?uuid='+uuid);
  return res.data;
});

const ofoParam = (showOfo: any) => {
  let params = [];
  if (showOfo.showUber) {
    params.push('ofo_slugs=ubereats')
  }
  if (showOfo.showDemaecan) {
    params.push('ofo_slugs=demaecan')
  }
  if (showOfo.showMenu) {
    params.push('ofo_slugs=menu')
  }
  if (showOfo.showWolt) {
    params.push('ofo_slugs=wolt')
  }
  if (showOfo.showSquare) {
    params.push('ofo_slugs=square')
  }
  if (showOfo.showTakeme) {
    params.push('ofo_slugs=takeme')
  }
  return params.join('&');
}

export const getCompletedOrders = createAsyncThunk('orders/getCompletedOrders', async (param:string) => {
  const res = await axios.get('/orders/completed'+param);
  return res.data;
});

type AcceptProps = {
  uuid: string,
  time: number,
}

export const acceptOrder = createAsyncThunk('orders/acceptOrder', async (body: AcceptProps) => {
  const res = await axios.post('/orders/status', {uuid: body.uuid, status:'ACCEPTED', time: body.time, need_resp: true});
  return res.data;
});

export const prepareOrder = createAsyncThunk('orders/prepareOrder', async (uuid: string) => {
  const res = await axios.post('/orders/status', {uuid: uuid, status:'PREPARED'});
  return res.data;
});

type TimeProps = {
  uuid: string,
  time: number,
}

export const updateTime = createAsyncThunk('orders/updateTime', async (param: TimeProps) => {
  const res = await axios.post('/orders/time', {uuid: param.uuid, time: param.time});
  return res.data;
});

export const healthCheck = createAsyncThunk('orders/healthCheck', async () => {
  const res = await axios.get('/');
  return res.data;
});

const orderListSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
      setOrder: (state, action: PayloadAction<Order>) => {
        state.order = action.payload;
      },
      // setOrderHandling: (state, action: PayloadAction<boolean>) => {
      //   state.handleLoading = action.payload;
      // },
      resetOrder: (state) => {
        state.order = new Order();
      },
      setPrintingOrder: (state) => {
        state.printingOrder = state.order;
      },
      resetPrintingOrder: (state) => {
        state.printingOrder =  null;
      },
      setShowUber: (state, action: PayloadAction<boolean>) => {
        state.showOfo.showUber = action.payload;
      },
      setShowDemaecan: (state, action: PayloadAction<boolean>) => {
        state.showOfo.showDemaecan = action.payload;
      },
      setShowMenu: (state, action: PayloadAction<boolean>) => {
        state.showOfo.showMenu = action.payload;
      },
      setShowWolt: (state, action: PayloadAction<boolean>) => {
        state.showOfo.showWolt = action.payload;
      },
      setShowSquare: (state, action: PayloadAction<boolean>) => {
        state.showOfo.showSquare = action.payload;
      },
      setShowTakeme: (state, action: PayloadAction<boolean>) => {
        state.showOfo.showTakeme = action.payload;
      },
      setActiveLoading: (state, action: PayloadAction<boolean>) => {
        state.activeLoading = action.payload;
      },
      resetPrintOrder: (state) => {
        state.printingOrder = null;
      },
      resetOrderList: (state) => {
        state.active = [];
        state.completed = [];
        state.totalOrder = 0;
        state.totalSales = 0;
      },
    },
    extraReducers: (builder) => {
      // get order
      builder.addCase(getOrder.pending, (state, action) => {
        state.printingOrder = null;
        state.orderLoading = true;
      });
      builder.addCase(getOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.order = action.payload.data;
      });
      builder.addCase(getOrder.rejected, (state, action) => {
        state.orderLoading = false;
      });
      builder.addCase(getCompletedOrders.pending, (state, action) => {
        state.completedLoading = true;
      });
      builder.addCase(getCompletedOrders.fulfilled, (state, action) => {
        state.completedLoading = false;
        state.completed = action.payload.data;
				state.totalOrder = state.completed.reduce((order, o:Order) => order + (o.isCanceled ? 0 : 1), 0);
        state.totalSales = state.completed.reduce((sales, o:Order) => sales + (o.isCanceled ? 0: o.subtotal), 0);
      });
      builder.addCase(getCompletedOrders.rejected, (state, action) => {
        state.completedLoading = false;
      });
      builder.addCase(acceptOrder.pending, (state, action) => {
        const orderUuid = action.meta.arg.uuid;
        state.handleLoadings[orderUuid] = true;
      });
      builder.addCase(acceptOrder.fulfilled, (state, action) => {
        const orderUuid = action.meta.arg;
        delete state.handleLoadings[orderUuid.uuid];

        state.order = action.payload.data;
      });
      builder.addCase(acceptOrder.rejected, (state, action) => {
        const orderUuid = action.meta.arg;
        delete state.handleLoadings[orderUuid.uuid];
      });
      builder.addCase(prepareOrder.pending, (state, action) => {
        const orderUuid = action.meta.arg;
        state.handleLoadings[orderUuid] = true;
      });
      builder.addCase(prepareOrder.fulfilled, (state, action) => {
        const orderUuid = action.meta.arg;
        delete state.handleLoadings[orderUuid];
        state.order = action.payload.data;
      });
      builder.addCase(prepareOrder.rejected, (state, action) => {
        const orderUuid = action.meta.arg;
        delete state.handleLoadings[orderUuid];
      });
      builder.addCase(updateTime.pending, (state, action) => {
        const orderUuid = action.meta.arg.uuid;
        state.handleLoadings[orderUuid] = true;
      });
      builder.addCase(updateTime.fulfilled, (state, action) => {
        const orderUuid = action.meta.arg.uuid;
        delete state.handleLoadings[orderUuid];
      });
      builder.addCase(updateTime.rejected, (state, action) => {
        const orderUuid = action.meta.arg.uuid;
        delete state.handleLoadings[orderUuid];
      });
    },
})

export const { setOrder, resetOrder, setPrintingOrder, resetPrintingOrder, setShowUber, setShowDemaecan, setShowMenu, setShowWolt, setShowSquare, setShowTakeme, setActiveLoading, resetPrintOrder, resetOrderList } = orderListSlice.actions

export const selectOrder = (state: any) => {return state.order.order};
export const selectPrintingOrder = (state: any) => {return state.order.printingOrder};
export const selectOrderLoading = (state: any) => {return state.order.orderLoading};
export const orderHandlingLoading = (state: any) => {return state.order.handleLoading}
export const selectIsNew = (state: any) => {return state.order.isNew};
export const selectActiveOrders = (state: any) => {return state.order.active};
export const selectCompletedOrders = (state: any) => {return state.order.completed};
export const selectTotalOrder = (state: any) => {return state.order.totalOrder};
export const selectTotalSales = (state: any) => {return state.order.totalSales};
export const selectActiveOrderListLoading = (state: any) => {return state.order.activeLoading};
export const selectCompletedOrderListLoading = (state: any) => {return state.order.completedLoading};
export const orderHandlingLoadings = (state: any) => {return state.order.handleLoadings}
export const selectShowOfo = (state: any) => {return state.order.showOfo};
export const selectOfoParam = (state: any) => {
  return ofoParam(state.order.showOfo);
};

export default orderListSlice.reducer