import { BrowserRouter, Routes, Route } from "react-router-dom";
import Top from "./views/Top";
import Signin from "./views/Signin";
import Home from "./views/Home";
import axios from 'axios';
import { auth } from './firebase';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.interceptors.request.use((config) => {
  if (config.headers !== undefined) {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  }
  return config
})
axios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      if (error.response.data.message === 'token expired') {
        const user = auth.currentUser
        if (user != null) {
          const token = await user.getIdToken(true)
          localStorage.setItem('token', token)
          error.config.headers['Authorization'] = `Bearer ${token}`
          return axios.request(error.config)
        }
      }
      auth
        .signOut()
        .then(() => {
          localStorage.removeItem('token')
        })
      return Promise.reject(error)
    }
    // const title = navigator.language === 'ja' ? 'エラー' : 'Error'
    // const message = navigator.language === 'ja' ? '処理に失敗しました' : 'Failed to process.'
    // console.log(`${title}: ${message}`);
    // ElMessageBox.alert(message, title, { dangerouslyUseHTMLString: false, confirmButtonText: 'OK' })
    return Promise.reject(error)
  }
)

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Top />} />
        <Route path={`/signin/`} element={<Signin />} />
        <Route path={`/order`} element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;